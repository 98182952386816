<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="topsubmenu auto">
          <a-radio-group default-value="1" size="small" @change="changeSubmenu" button-style="solid">
            <a-radio-button value="1">
              账号安全策略
            </a-radio-button>
            <a-radio-button value="4">
              Syslog配置
            </a-radio-button>
            <a-radio-button value="5">
              属性配置
            </a-radio-button>
            <a-radio-button value="2">
              基本配置
            </a-radio-button>
            <a-radio-button value="3">
              系统准入策略
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="drawer-bar auto">
          <div class="fl">
            <span class="font16"><b>账号安全策略</b></span>
          </div>
        </div>
        <div class="department-content auto" id="no-margin-form">
          <div class="box auto">
            <div class="box-title auto">
              <div class="fl">密码校验管理</div>
            </div>
            <div class="box-con auto m-t-20">
              <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="{span: 4}"
                :wrapper-col="{span: 19}"
                labelAlign="left"
                style="max-width: 800px;"
              >
                <a-form-model-item label="密码最小长度" prop="password_length_min">
                  <a-input type="number" v-model="form.password_length_min" size="small" :min="6" :max="form.password_length_max" @blur="form.password_length_min < 6 ? form.password_length_min = 6 : form.password_length_min > form.password_length_max ? form.password_length_min = form.password_length_max : ''" style="width:68px;margin-left:-34px;"></a-input>
                </a-form-model-item>
                <a-form-model-item label="密码最大长度" prop="password_length_max">
                  <a-input type="number" v-model="form.password_length_max" size="small" :min="form.password_length_min" :max="40" @blur="form.password_length_max < form.password_length_min ? form.password_length_max = form.password_length_min : form.password_length_max > 40 ? form.password_length_max = 40 : ''" style="width:68px;margin-left:-34px;"></a-input>
                </a-form-model-item>
                <a-form-model-item label="密码规则" prop="rule">
                  <div style="margin-left:-60px;">
                    <!-- <a-checkbox-group v-model="form.rule" :options="ruleOptions" /> -->
                    <a-checkbox v-model="form.password_regex_numerial">数字</a-checkbox>
                    <a-checkbox v-model="form.password_regex_lowercase">小写字母</a-checkbox>
                    <a-checkbox v-model="form.password_regex_uppercase">大写字母</a-checkbox>
                    <a-checkbox v-model="form.password_regex_specificsymbol">符号</a-checkbox>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="密码最少种类" prop="password_type_min">
                  <a-input type="number" v-model="form.password_type_min" size="small" :min="0" :max="4" @blur="form.password_type_min < 0 ? form.password_type_min = 0 : form.password_type_min > 4 ? form.password_type_min = 4 : ''" style="width:68px;margin-left:-34px;"></a-input>
                </a-form-model-item>
                <a-form-model-item label="密码例外字符" prop="password_exception_punctuation">
                  <a-textarea v-model="form.password_exception_punctuation" size="small" :auto-size="{ minRows: 2, maxRows: 2 }" style="width:312px;margin-left:-34px;" class="fl"></a-textarea>
                  <p class="fl">（直接输入单个或多个字符，不设置则不填写）</p>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-button type="primary" size="small" style="margin-top:15px;" @click="submitPasswordverify">保存</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
          <div class="box box2 auto">
            <div class="box-title auto">
              <div class="fl">弱口令字典</div>
            </div>
            <div class="box-con auto m-t-20">
              <a-form-model
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                labelAlign="left"
                style="width: 800px;"
              >
                <a-form-model-item label="弱口令文件上传">
                  <span style="margin-left: -55px;margin-right:8px;color:rgba(0, 0, 0, 0.85);">已上传{{passwordweaktimies}}次</span>
                  <a-upload
                    :spinning="uploadLoading"
                    :showUploadList="false"
                    name="file1"
                    :multiple="true"
                    method="PUT"
                    :action="config.api + 'system/securitypolicy/passwordweak'"
                    @change="handleChange"
                    :before-upload="beforeUpload"
                    style="display:inline-block;"
                  >
                    <a-button type="primary" size="small">上传</a-button>
                  </a-upload>
                  <span>（弱口令文件只支持上传，上传文件类型为.csv）</span>
                </a-form-model-item>
                <a-form-model-item label="弱口令重置">
                  <a-popconfirm
                    title="确定要重置吗?"
                    ok-text="确定"
                    cancel-text="取消"
                    placement="topRight"
                    @confirm="resetPassword"
                  >
                    <a-button size="small" style="margin-left: -83px;">重置</a-button>
                  </a-popconfirm>
                  <span>（重置将恢复为初始弱口令字典，请谨慎使用此功能）</span>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
          <div class="box box2 auto">
            <div class="box-title auto">
              <div class="fl">账号安全参数</div>
            </div>
            <div class="box-con auto m-t-20">
              <a-form-model
                ref="ruleForm"
                :model="form2"
                :rules="rules2"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                labelAlign="left"
                style="width: 800px;"
              >
                <a-form-model-item label="密码提醒修改周期（天）" prop="password_modify_cycle">
                  <a-input type="number" v-model="form2.password_modify_cycle" size="small" :min="0" :max="3650" @blur="form2.password_modify_cycle < 0 ? form2.password_modify_cycle = 0 : form2.password_modify_cycle > 3650 ? form2.password_modify_cycle = 3650 : ''" style="width:68px;"></a-input>
                  <span>（提醒用户修改密码的周期，设置为 0 禁用此功能）</span>
                </a-form-model-item>
                <a-form-model-item label="密码强制修改周期（天）" prop="password_forcereset_cycle">
                  <a-input type="number" v-model="form2.password_forcereset_cycle" size="small" :min="0" :max="3650" @blur="form2.password_forcereset_cycle < 0 ? form2.password_forcereset_cycle = 0 : form2.password_forcereset_cycle > 3650 ? form2.password_forcereset_cycle = 3650 : ''" style="width:68px;"></a-input>
                  <span>（强制用户修改密码的周期，设置为 0 禁用此功能）</span>
                </a-form-model-item>
                <a-form-model-item label="账号闲置锁定周期（天）" prop="account_lock_unuse">
                  <a-input type="number" v-model="form2.account_lock_unuse" size="small" :min="0" :max="3650" @blur="form2.account_lock_unuse < 0 ? form2.account_lock_unuse = 0 : form2.account_lock_unuse > 3650 ? form2.account_lock_unuse = 3650 : ''" style="width:68px;"></a-input>
                  <span>（超过该时长未登录的账号将转为闲置锁定状态，设置为 0 禁用此功能）</span>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-button type="primary" size="small" style="margin-top:15px;" @click="submitAccountsafety">保存</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
          <!-- <div class="box box2 auto">
            <div class="box-title auto">
              <div class="fl">跨地区登录强制双因素</div>
            </div>
            <div class="box-con auto no-bor m-t-20">
              <a-form-model
                ref="ruleForm"
                :model="form3"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                labelAlign="left"
                style="width: 800px;"
              >
                <a-form-model-item label="启用状态" prop="enabled">
                  <a-radio-group v-model="form3.enabled" style="margin-left: -95px;">
                    <a-radio value="1">
                      启用
                    </a-radio>
                    <a-radio value="2">
                      停用
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="白名单地区" prop="whiteIds">
                  <a-select placeholder="请选择地区" v-model="form3.whiteIds" size="small" mode="multiple" style="width:312px;margin-left: -83px;">
                    <a-select-option :value="item.id" v-for="(item, itemIndex) in addressOptions" :key="itemIndex">
                      {{item.name}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-button type="primary" size="small" style="margin-top:15px;" @click="submitAccountsafety">保存</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div> -->
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      loading: false,
      ruleOptions: [
        { label: '数字', value: 'number' },
        { label: '小写字母', value: 'lower' },
        { label: '大写字母', value: 'up' },
        { label: '符号', value: 'fh' },
      ],
      addressOptions: [
        {
          id: 1,
          name: '北京'
        },
        {
          id: 2,
          name: '上海'
        },
        {
          id: 3,
          name: '天津'
        },
        {
          id: 4,
          name: '海外'
        }
      ],
      form: {
        password_length_min: '',
        password_length_max: '',
        password_regex_numerial: false,
        password_regex_lowercase: false,
        password_regex_uppercase: false,
        password_regex_specificsymbol: false,
        password_type_min: 0,
        password_exception_punctuation: ''
      },
      rules: {
        // min: [
        //   { required: true, message: '请输入最小长度', trigger: 'blur' }
        // ]
      },
      form2: {
        password_modify_cycle: '',
        password_forcereset_cycle: '',
        account_lock_unuse: ''
      },
      rules2: {
        // min: [
        //   { required: true, message: '', trigger: 'blur' },
        //   { min: 3, max: 5, message: '', trigger: 'blur' },
        // ]
      },
      passwordweaktimies: 0,
      uploadLoading: false,
      form3: {
        enabled: '1',
        whiteIds: []
      }
    }
  },
  methods: {
    changeSubmenu (e) {
      var value = Number(e.target.value)
      console.log('value', value)
      switch (value) {
        case 1:
          this.$router.push('/system/configuration/index');
          break;
        case 2:
          this.$router.push('/system/configuration/basic');
          break;
        case 3:
          this.$router.push('/system/configuration/accesspolicy');
          break;
        case 4:
          this.$router.push('/system/configuration/syslogsetting');
          break;
        case 5:
          this.$router.push('/system/configuration/property');
          break;
        default:
          break;
      }
    },
    beforeUpload (file) {
      // console.log(file)
      const isTextCSV = file.type === 'text/csv';
      if (!isTextCSV) {
        this.$message.error('只支持csv格式！');
      }
      return isTextCSV
    },
    handleChange(info) {
      this.uploadLoading = true
      const status = info.file.status;
      if (status === 'done') {
        if (!info.file.response['code']) {
          this.$message.success('上传成功！')
          this.getPasswordverify()
        } else {
          this.$message.error(this.errMsg[info.file.response['code']])
        }
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    async resetPassword () {
      let res = await System.putPasswordweakRest()
      if (!res['code']) {
        this.$message.success('重置成功！')
        this.getPasswordverify()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getPasswordverify () {
      this.loading = true
      let res = await System.getPasswordverify()
      if (!res['code']) {
        this.form = {
          password_length_min: res['data']['password_length_min'],
          password_length_max: res['data']['password_length_max'],
          password_regex_numerial: res['data']['password_regex_numerial'] ? true : false,
          password_regex_lowercase: res['data']['password_regex_lowercase'] ? true : false,
          password_regex_uppercase: res['data']['password_regex_uppercase'] ? true : false,
          password_type_min: res['data']['password_type_min'],
          password_exception_punctuation: res['data']['password_exception_punctuation'] ? res['data']['password_exception_punctuation'] : ''
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
      let res2 = await System.getAccountsafety()
      if (!res2['code']) {
        this.form2 = {
          password_modify_cycle: res2['data']['password_modify_cycle'],
          password_forcereset_cycle: res2['data']['password_forcereset_cycle'],
          account_lock_unuse: res2['data']['account_lock_unuse']
        }
      } else {
        // this.$message.error(res2['description'])
        this.$message.error(this.errMsg[res2['code']])
      }
      let res3 = await System.getPasswordweak()
      if (!res3['code']) {
        this.passwordweaktimies = res3['data']
      } else {
        this.$message.error(this.errMsg[res3['code']])
      }
      let res4 = await System.getAcrossregion()
      this.loading = false
      if (!res4['code']) {
        this.form3 = res4['data']
        console.log(res4)
      } else {
        this.$message.error(this.errMsg[res4['code']])
      }
    },
    async submitPasswordverify () {
      let form = {
        password_length_min: this.form['password_length_min'],
        password_length_max: this.form['password_length_max'],
        password_regex_numerial: this.form['password_regex_numerial'] ? 1 : 0,
        password_regex_lowercase: this.form['password_regex_lowercase'] ? 1 : 0,
        password_regex_uppercase: this.form['password_regex_uppercase'] ? 1 : 0,
        password_regex_specificsymbol: this.form['password_regex_specificsymbol'] ? 1 : 0,
        password_type_min: this.form['password_type_min'],
        password_exception_punctuation: this.form['password_exception_punctuation']
      }
      this.loading = true
      let res = await System.putPasswordverify(form)
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async submitAccountsafety () {
      this.loading = true
      let newForm = JSON.parse(JSON.stringify(this.form2))
      // newForm['whiteIds'] = newForm['whiteIds'].join(',')
      // newForm['whiteIds'] = JSON.stringify(newForm['whiteIds'])
      let res = await System.putAccountsafety(newForm)
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getPasswordverify()
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
  color: rgba(0,0,0,0.85);
}

.box {
}
.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  width: 100%;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  width: 100%;
  padding-bottom: 20px;
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}

.topsubmenu {
  padding-bottom: 0;
}
.coustom-title {
  color: rgba(0,0,0,0.85);
}

</style>
